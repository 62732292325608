const imageSizes = require('./constants/image.json');

const BREAKPOINTS_DEVICES = {
  MOBILE: 320,
  MOBILEBIG: 480,
  PHABLET: 660,
  TABLET: 768,
  DESKTOP: 960,
  WIDE: 1366
};

const RESPONSIVE_IMAGE_CONFIG = process.env.DISABLE_RESPONSIVE_IMAGES
  ? null
  : {
      sizes: {
        socialMeta: {
          single: {
            r: {
              width: 1920
            }
          }
        },
        'search-result': {
          widths: {
            '320w': {
              // single size
              r: {
                width: 320
              }
            }
          },
          sizes: '100vw'
        },
        'card:hero:grid--one': {
          widths: imageSizes.standardWidths,
          sizes: '(max-width: 1022px) 55vw, 77vw'
        },
        'card:portrait:grid--three': {
          widths: imageSizes.standardWidths,
          sizes: '(max-width: 659px) 43vw, 78vw'
        },
        'card:portrait:grid--three:squares': {
          widths: imageSizes.squares,
          sizes: '(max-width: 659px) 43vw, 78vw'
        },
        'card:portrait:grid--four': {
          widths: imageSizes.standardWidths,
          sizes: '(max-width: 1022px) 57vw, (max-width: 766px) 89vw, 31vw'
        },
        'card:portrait:grid--four:squares': {
          widths: imageSizes.squares,
          sizes: '(max-width: 1022px) 57vw, (max-width: 766px) 89vw, 31vw'
        },
        'card:landscape:grid--one': {
          widths: imageSizes.standardWidths,
          sizes: '13vw'
        },
        'card:landscape:grid--one:squares': {
          widths: imageSizes.squares,
          sizes: '15vw'
        },
        'card:landscape:grid--one:home': {
          widths: imageSizes.standardWidths,
          sizes: '(max-width: 767px) 100vw, 91vw'
        },
        'card:portrait:grid--two': {
          widths: imageSizes.standardWidths,
          sizes: '(max-width: 767px) 100vw, 50vw'
        },
        'card:portrait:grid--two::squares': {
          widths: imageSizes.squares,
          sizes: '(max-width: 767px) 100vw, 50vw'
        },
        'card:landscape:grid--two': {
          widths: imageSizes.standardWidths,
          sizes: '(max-width: 767px) 53vw, 45vw'
        },
        'card:landscape:grid--two:squares': {
          widths: imageSizes.squares,
          sizes: '(max-width: 767px) 53vw, 45vw'
        },
        card: {
          widths: imageSizes.standardWidths,
          sizes: '100vw'
        },
        globalLightbox: {
          widths: imageSizes.standardWidths,
          sizes: '100vw'
        },
        'image:responsive': {
          widths: imageSizes.standardWidths,
          sizes: '100vw'
        },
        image: {
          widths: imageSizes.standardWidths,
          sizes: '100vw'
        },
        'image:squares': {
          widths: imageSizes.squares,
          sizes: '100vw'
        },
        carousel: {
          widths: {
            // 525w is wrong but will trigger srcset correctly
            '525w': {
              r: {
                height: 350
              }
            }
          },
          sizes: '100vw'
        }
      }
    };

export { BREAKPOINTS_DEVICES, RESPONSIVE_IMAGE_CONFIG };
