import examplePlugin from './blaze-plugins/example';

export default function buildConfig(config) {
  // To set custom plugins into the project.
  config.plugins.set(examplePlugin, {});

  // eslint-disable-next-line no-param-reassign
  config.apollo = {
    ...config.apollo,
    disableUpload: true
  };

  return config;
}
